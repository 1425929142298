<template>
    <div class="row">
        <div class="form-group row mt-6">
            <div class="col-lg-6  fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <select :class="(validate==true && !il_id) ? 'border border-danger' :''" v-model="il_id" name="country" aria-label="Select a Country"  class="form-select form-select-solid form-select-lg fw-bold select2-hidden-accessible"  aria-hidden="true" >
                    <option value="" data-select2-id="select2-data-12-eja2">{{l('admin:languages:il')}}</option>
                    <option v-for="datas in data" :key="datas.id"  :value="datas.id" data-select2-id="select2-data-164-bxrk">{{datas.il_adi}}</option>
                </select>
            </div>
            <div class="col-lg-6 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <select :class="(validate==true && !adres.district) ? 'border border-danger' :''" v-model="adres.district"  name="country" aria-label="Select a Country"  class="form-select form-select-solid form-select-lg fw-bold select2-hidden-accessible"  aria-hidden="true" >
                    <option value="" data-select2-id="select2-data-12-eja2">{{l('admin:languages:languages435')}}</option>
                    <option v-for="ilce in counties" :key="ilce.id"  :value="ilce.ilce_adi" data-select2-id="select2-data-164-bxrk">{{ilce.ilce_adi}}</option>
                </select>
            </div>
            <div class="col-lg-12 mt-4">
                <textarea :class="(validate==true && !adres.address) ? 'border border-danger' :''" v-model="adres.address" class="form-control form-control-solid" rows="4" name="message" :placeholder="l('admin:genel:adres')"></textarea>
                <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div class="d-flex justify-content-end mt-6">
                <button @click="emitAdd('finish')" type="button" class="btn btn-primary">{{l('admin:genel:gonder')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent,onMounted, ref, watch, reactive, computed } from "vue";
import get from "@/core/functions/get";
import post from "@/core/functions/post";
import {api_url} from '@/ayar';
import { l } from "@/core/helpers/lang";

export default defineComponent({

    name : 'Adress',
    data(){
        return {
            il : [],

        }
    },
    setup(){
        const data = ref(null);
        const counties = ref(null)
        const il_id = ref('');
        const validate = ref(false);

        const adres = reactive({
            city: '',
            district : '',
            address : '',
        });

        function fetchData() {
            get(api_url+'cities').then((response)=> {
                data.value = response.data;    
            });
        }

        function county(id){
            post(api_url+'district', {'id':id}).then((response)=> {
                counties.value = response.data;    
            }); 
        }

        function emitAdd(event){
            this.$emit('adres', adres);
            this.$emit('isActive', event);
        }

        watch(il_id, (newVal, oldVal) => {
            county(newVal);
            oldVal = newVal - 1;
            adres.city  = data.value[oldVal].il_adi;
            adres.district = null;
        });
    
        
        onMounted(() => {
            fetchData();
            
        });

        return {
           data,
           il_id,
           counties,
           adres,
           validate,
           l,
           emitAdd
        }
    }

});
</script>