<template>
  <div class="wizard-fiel">
    <div class="fv-row mb-10 mt-10">
      <!--begin::Label-->

      <!--end::Label-->
      <vue-tel-input
        class="form-control form-control-lg form-control-solid shadow-none"
        :preferredCountries="['TR']"
        @input="onInput"
        v-model="phone"
        v-bind="bindProps"
        style="outline: 0" />
      <!--end::Input-->
      <div class="d-flex justify-content-end mt-6">
        <button @click="emitAdd()" type="button" class="btn btn-primary">{{nextText}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import $ from "jquery";
import Inputmask from "inputmask";
import { l } from "@/core/helpers/lang";
import post from "@/core/functions/post";
import { api_url } from "@/ayar";
import { useStore } from "vuex";
import Swal from 'sweetalert2'
import toast from '@/core/functions/toast';


export default defineComponent({
  name: "TelForm",
  components: {
    VueTelInput
  },
  data: () => {
    return {
      phone: "",
      nextText : l('admin:transfer:Sonraki'),
      bindProps: {
        placeholder: l("admin:languages:langugaes434"),
        required: false,
        autocomplete: "off",
        name: "phone",
        autoFormat: false,
        customValidate: false,
        validCharactersOnly: true,
        value: null,
        inputOptions: {
          showDialCode: false,
          styleClasses: "shadow-none"

        }
      }
    };
  },
  methods: {
    onInput(phone, phoneObject) {
      if (phoneObject) {
        if (phoneObject.formatted) {
          this.phone = phoneObject.country.dialCode + phoneObject.nationalNumber;
        }
      }

    },
    emitAdd() {
      console.log(this.phone);
      if (this.phone.length>=12) {
          post(api_url  + `inforCheck`, {"phone": this.phone}).then(res => {
              if(res.data.success){
                    this.sweetAlert();
              }else {
                  toast(l('admin:transfer:Bu telefon numarası sistemde kayıtlı.'), false);
              }
          });

      } else {
  
        toast(l('admin:transfer:Telefon numarası boş geçilemez.'),false);

      }
    },
    sweetAlert(){
      Swal.fire ( {
					title              : this.phone +' '+l('user:languages:languages269'),
					input              : 'text',
					showCancelButton   : true,
					confirmButtonText  : 'Tamam',
					showLoaderOnConfirm: true,
          preConfirm         : ( new_test_amount ) => {
            	if ( new_test_amount )
						  {
                post(api_url+'informationSmsCheck', {"gsm":this.phone, "code":new_test_amount}).then(response => {
                  if(response.data.durum){
                    this.$emit("isActive", 'acount');
                    this.$emit("phone", this.phone);
                  }else {
                    toast(l('admin:genel:Doğrulama Kodu Yanlış'), false);
                  }
                });
              }
          }
				} );
    }
  },
  mounted() {
    const store = useStore();
    const userPhone = ref("");

    const user = computed(() => store.getters.currentUser);
    post(api_url + `user/` + user.value.sponsor_id, {}).then(res => {
        this.phone = res.data.data.phone;
        this.bindProps.value=res.data.data.phone.substr(2)
    });


    $(document).ready(function() {
      Inputmask({ "mask": "(999) 999-9999" }).mask(".vti__input");
    });


    return {
      userPhone
    };
  }
});
</script>