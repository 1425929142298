<template>

    <div class="wizard-fiel">
        <div class="fv-row mb-10 mt-10">
                <div class="form-group row">
                    <label class="mb-4 text-danger fw-bolder" v-if="validate">{{l('admin:transfer:Seçiminizi Yapın')}}</label>
                    <div class="col-md-12 col-lg-12 col-xxl-12">
                        <!--begin::Option-->
                        <label class=" d-flex text-start  active" data-kt-button="true">
                            <!--begin::Radio-->
                            <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input v-model="acount" class="form-check-input" type="radio" name="finance_usage" value="1" :checked="type==1 || acount==1" />
                            </span>
                            <!--end::Radio-->
                            <!--begin::Info-->
                            <span class="ms-5">
                                <span class="fs-5 fw-bolder text-gray-800 mb-2 d-block">{{l('admin:transfer:Temsilci olarak kayıt olun')}}</span>
                            </span>
                            <!--end::Info-->
                        </label>
                        <!--end::Option-->
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xxl-12">
                        <!--begin::Option-->
                        <label class="d-flex text-start  active" data-kt-button="true">
                            <!--begin::Radio-->
                            <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input v-model="acount" class="form-check-input"  type="radio" name="finance_usage" value="0" :checked="type==0 || acount==0"  />
                            </span>
                            <!--end::Radio-->
                            <!--begin::Info-->
                            <span class="ms-5">
                                <span class="fs-6 fw-bolder text-gray-800 mb-2 d-block">{{l('admin:transfer:Müşteri olarak kayıt olun')}}</span>
                            </span>
                            <!--end::Info-->
                        </label>

                        <!--end::Option-->
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                </div>            

                <div class="d-flex justify-content-end mt-6">
                    <button @click="emitAdd(PageParametre)" type="submit" class="btn btn-primary">{{l('admin:transfer:Sonraki')}}</button>
                </div>
          
        </div>
    </div>
  
</template>

<script >
import { l } from '@/core/helpers/lang';
import {defineComponent,computed, ref} from "vue";
import {useStore} from 'vuex';

export default defineComponent({
    name : 'AcountType',
    data(){
        return {
            acount : 1,
            validate : false
        }
    },
    methods: {
        emitAdd(event){
            if(this.acount){
                this.$emit('isActive', event);
                this.$emit('user_type', this.acount);
            }else {
                this.validate = true;
            }
        }
    }, 
    props: ['type'],
    mounted(){
        if(this.type){
            this.acount = this.type;
        }
    },
    setup(){
        const store = useStore()
        const PageParametre = ref('');

        const parameters = computed(() => store.getters.getParameterList);

        
        if(parameters.value.form_register_tc){
            PageParametre.value = 'tc';
        }else {
            PageParametre.value = 'usertype';
        }
        

        return {
            l,
            parameters,
            PageParametre
        }
    }
})
</script>

<style scoped>
.radio.radio-lg > span {
    height: 24px;
    width: 24px;
    background-color: #EBEDF3;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.radio-inline .radio {
    margin-right: 1rem;
    margin-bottom: 0.35rem;
}
.form-group label {
    font-size: 1rem;
    font-weight: 400;
    color: #3F4254;
}
.radio > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0;
}
.radio > input:checked ~ span {
    background-color: #6993FF;
}
.radio > input:checked ~ span:after {
    display: block;
    background-color: #ffffff;
    border-color: #ffffff;
    width: 8px;
    height: 8px;
}
</style>
