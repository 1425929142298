
import {defineComponent, ref, onMounted, computed} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {api_url} from '@/ayar';
import post from "@/core/functions/post";
// import Amchart from '@/components/backoffice/chart/Amchart4.vue';
// import Amchart2 from '@/components/backoffice/chart/Amchart4_2.vue';
import _ from 'lodash';
// import SwitchChecker from '@/components/backoffice/dashboard/components/SwitchChecker.vue';
import Information from '@/components/backoffice/setup/Information.vue';
import {MenuComponent} from "@/assets/ts/components";
import {getUiAvatarSrc} from "@/ayar";
import { l }           from '@/core/helpers/lang';
import ArrowIndicatorIcon from "@/components/backoffice/dashboard/components/ArrowIndicatorIcon.vue";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import NumberFormat from "@/core/functions/NumberFormat";

export default defineComponent({
  name: "Ozet",
  components: {
    ArrowIndicatorIcon,
    // SwitchChecker,
    // Amchart,
    // Amchart2,
    Information
  },
  methods: {
    getFilteredEarnings(data, maxFieldCount = 3) {
      return _.take(data, maxFieldCount);
    }
  },
  setup() {
    let dashboard = ref({})
    let banner = ref({});
    let bannerImg = ref('');

    const getDashboard = async () => {
      let response = await post(api_url + 'dashboard');
      dashboard.value = await response.data;
    }

    const getBanner = async () => {
        let response = await post(api_url + 'backOfficeTasarim');
        bannerImg.value = await response.data.image_url;
        banner.value = await response.data.options;
    }

    onMounted(getDashboard);
    onMounted(getBanner);  

    onMounted(() => {
      setCurrentPageTitle(l('admin:genel:dashboard'));
      MenuComponent.reinitialization();

      const myCarousel = document.querySelector('#kt_sliders_widget_1_slider')
      new bootstrap.Carousel(myCarousel, {
        interval: 5500,
        wrap: true,
        ride: 'carousel'
      })
      const myCarousel2 = document.querySelector('#kt_sliders_widget_2_slider')
      new bootstrap.Carousel(myCarousel2, {
        interval: 5500,
        wrap: true,
        ride: 'carousel'
      })

    })

    return {
      dashboard,
      banner,
      bannerImg,
      getUiAvatarSrc,
      NumberFormat,
      l
    }
  },
});
