<template>
    <div class="form-group">
        <div class="mt-10 d-flex flex-column">
            <label class=" mb-2 fs-4">{{l('admin:transfer:TC Kimlik Numaranız')}}*</label>
        </div>
        
        <input :class="(validInput) ? 'border border-danger' : ''" data-inputmask="'mask': '99999999999'"  type="text" class="form-control" placeholder="___________" v-model="tc"/>
        <div class="d-flex justify-content-end mt-6">
            <div class="w-100">
                <button @click="emitAdd('acount')" type="button" class="btn btn-primary">{{l('admin:transfer:Önceki')}}</button>
            </div>
            <button @click="emitAdd('usertype')" type="button" class="btn btn-primary">{{l('admin:transfer:Sonraki')}}</button>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import $ from 'jquery';
import Inputmask from "inputmask";
import { l } from '@/core/helpers/lang';

export default defineComponent({
    name : 'TcUser',
    data(){
        return {
            tc : '___________',
            validInput: false,
        }
    },
    methods: {
        emitAdd(event){
            const regExp = /^\d*(\.)?(\d{0,2})?$/;
            
                if(regExp.test(this.tc) || event=='acount'){
                    this.$emit('isActive', event);
                    this.$emit('tc', this.tc);
                }else {
                    this.validInput = true;
                }
            
           
        },
    
    },
    setup(){
        
        $(document).ready(function(){
            Inputmask().mask(document.querySelectorAll("input"));
        });
        return {
            l
        }
    }
});
</script>