<template>
      <!--       Modal         -->
     
      <div v-if="information===0" class="modal fade " id="urunModalsing" data-keyboard="false" data-bs-backdrop="static"  tabindex="-1" role="dialog" aria-labelledby="exampleModalSizeLg" aria-hidden="true">
        
        <div class="modal-dialog mw-900px modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body row" >
                <div class="col-8">
                    <div class="w-100 lh-base">
                        <div class="fs-1 fw-bold mb-2">{{l('admin:transfer:Hoş geldiniz')}}</div>
                        <div class="fs-3 ">{{l('admin:transfer:Birkaç basit adımla kaydınızı tamamlayalım')}}</div>
                    </div>
                      
                    <tel-form 
                    @isActive="isActive= $event" 
                    v-if="isActive === 'tel'" 
                    @phone="data.phone = $event"
                    :sponsor_id="sponsor_id">
                    </tel-form>

                    <acount-type 
                    @isActive="isActive= $event" 
                    @user_type="data.user_type = $event" 
                    :type="data.user_type"
                    v-if="isActive === 'acount'">
                    </acount-type>

                    <tc-user 
                    @isActive="isActive= $event"
                    @tc="data.tc=$event" 
                    v-if="isActive === 'tc'">
                    </tc-user>

                    <user-type 
                    @kurumsalInfo="data.kurumsal=$event" 
                    @isActive="isActive= $event" 
                    v-if="isActive === 'usertype'"
                    :kurumsal="data.kurumsal"
                    >
                    </user-type>
                    <email @email="data.email=$event"   @isActive="isActive= $event" v-if="isActive === 'email'"></email>
                    <adress @adres="data.adres=$event" @isActive="isActive= $event" v-if="isActive === 'adres' || isActive === 'finish'"></adress>
                </div>
                <div class="col-4">
                  <h3 class="text-center">{{generalSettings.domain}}</h3>
                  <div class="back-image" style=" background: url(media/img/emc-mlm-agac.png);background-repeat: no-repeat; background-position: center;   background-size: 70%;"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!--       Modal         -->
</template>

<script>
import {computed, defineComponent, ref, watch} from "vue"; 
import store from "@/store";
import post from "@/core/functions/post";
import {api_url} from '@/ayar';
import TelForm from './components/TelForm.vue';
import AcountType from './components/AcountType.vue';
import TcUser from './components/TcUser.vue';
import UserType from './components/UserType.vue';
import Email from './components/Email.vue';
import Adress from './components/Adress.vue';
import { Mutations } from "@/store/enums/StoreEnums";
import { l } from '@/core/helpers/lang';
import toast from '@/core/functions/toast';

export default defineComponent({
  name : 'Test',
  data(){
    return {
      active : true
    }
  },
  components: {
    TelForm,
    AcountType,
    TcUser,
    Email,
    UserType,
    Adress
  },
  setup(){
    
    const isActive = ref('tel');

    const data = ref({
        phone : '',
        tc : '',
        user_type : null,
        email : '',
        adres : {
          city: '',
          district : '',
          address : '',
        },
        kurumsal : {
          sirket_temsilcisi : '',
          sirket_unvani : '',
          vergi_no : '',
          vergi_dairesi :''
        }
    });

    watch(isActive, (newVal, oldVal) => {
      if(newVal=='finish'){
          const json = JSON.parse(JSON.stringify(data.value));

          post(api_url+'information', json).then((response)=> {
              if(response.data.success){
                 toast(l('admin:languages:languages322'), true);
                 store.commit(Mutations.SET_INFO);
              }
          }); 
      }
    })

    return {
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
      information : computed(() => store.getters.currentUser.information),
      sponsor_id : computed(() => store.getters.currentUser.sponsor_id),
      isActive,
      data,
      l
    }
  }
});
</script>

<style  scoped>
   .back-image {
      min-height: 28rem;
      
   }

   #urunModalsing {
      display: block !important;
      transition: opacity 0.15s linear;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1060;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      outline: 0;
      opacity: 1;
      background: #3134368c;  
      padding: 0;
      margin: 0;
   }
    
    
</style>