import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex mt-8 mb-6" }
const _hoisted_2 = {
  class: " d-flex text-start  active ms me-6",
  "data-kt-button": "true"
}
const _hoisted_3 = { class: "form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1" }
const _hoisted_4 = { class: "ms-5" }
const _hoisted_5 = { class: "fs-5 fw-bolder text-gray-800 mb-2 d-block" }
const _hoisted_6 = {
  class: "d-flex text-start  active",
  "data-kt-button": "true"
}
const _hoisted_7 = { class: "form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1" }
const _hoisted_8 = { class: "ms-5" }
const _hoisted_9 = { class: "fs-6 fw-bolder text-gray-800 mb-2 d-block" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "form-group row" }
const _hoisted_12 = { class: "col-md-6 mt-2" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "col-md-6  mt-2" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "col-md-6  mt-2" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "col-md-6  mt-2" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "d-flex justify-content-end mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.type = $event)),
            value: "bireysel",
            class: "form-check-input",
            type: "radio",
            name: "finance_usage",
            checked: "checked"
          }, null, 512), [
            [_vModelRadio, _ctx.type]
          ])
        ]),
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.l('admin:genel:bireysel')), 1)
        ])
      ]),
      _createElementVNode("label", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.type = $event)),
            value: "kurumsal",
            class: "form-check-input",
            type: "radio",
            name: "finance_usage"
          }, null, 512), [
            [_vModelRadio, _ctx.type]
          ])
        ]),
        _createElementVNode("span", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.l('admin:genel:kurumsal')), 1)
        ])
      ])
    ]),
    (_ctx.type=='kurumsal')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("form", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass([(!_ctx.data.sirket_unvani && _ctx.validate) ? 'border border-danger' : '', "form-control form-control-solid"]),
                type: "text",
                id: "sirket_unvani",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.data.sirket_unvani = $event)),
                placeholder: _ctx.l('admin:transfer:Şirket Ünvanı'),
                name: "details_title"
              }, null, 10, _hoisted_13), [
                [_vModelText, _ctx.data.sirket_unvani]
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.data.sirket_temsilcisi = $event)),
                class: _normalizeClass(["form-control form-control-solid", (!_ctx.data.sirket_temsilcisi && _ctx.validate) ? 'border border-danger' : '']),
                placeholder: _ctx.l('admin:genel:sirket_temsilcisi')
              }, null, 10, _hoisted_15), [
                [_vModelText, _ctx.data.sirket_temsilcisi]
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.data.vergi_no = $event)),
                class: _normalizeClass(["form-control form-control-solid", (!_ctx.data.vergi_no && _ctx.validate) ? 'border border-danger' : '']),
                placeholder: _ctx.l('admin:genel:vergi_no')
              }, null, 10, _hoisted_17), [
                [_vModelText, _ctx.data.vergi_no]
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.data.vergi_dairesi = $event)),
                class: _normalizeClass(["form-control form-control-solid", (!_ctx.data.vergi_dairesi && _ctx.validate) ? 'border border-danger' : '']),
                placeholder: _ctx.l('admin:genel:vergi_dairesi')
              }, null, 10, _hoisted_19), [
                [_vModelText, _ctx.data.vergi_dairesi]
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("button", {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.emitAdd('email'))),
        type: "button",
        class: "btn btn-primary"
      }, _toDisplayString(_ctx.l('admin:transfer:Sonraki')), 1)
    ])
  ], 64))
}