
import { l } from '@/core/helpers/lang';
import {defineComponent} from "vue";
export default defineComponent({
    data(){
        return {
            type : null,
            validate : false,
            data : 
                {
                    sirket_temsilcisi : '',
                    sirket_unvani : '',
                    vergi_no : '',
                    vergi_dairesi :'',
                }
            ,
        }
    },
    methods: {
        emitAdd(event){

            if(this.type=='bireysel' || !this.type){
                this.$emit('isActive', event);
                this.$emit('kurumsalInfo', this.data);
            }else {
                this.validate = true;

                if(this.validate && this.data.sirket_temsilcisi && this.data.sirket_unvani && this.data.vergi_no && this.data.vergi_dairesi ){
                    this.$emit('isActive', event);
                    this.$emit('kurumsalInfo', this.data);
                }
            }
         
        }
    }, 
    watch : {
        type : function (event){
            if(event=="bireysel"){
                this.validate = false; 
                this.data.sirket_temsilcisi = '',
                this.data.sirket_unvani = '',
                this.data.vergi_no = '',
                this.data.vergi_dairesi = ''
            }
        }
    },
    setup(){

        return {
            l
        }
    }
})
