
import {defineComponent} from 'vue';
import { l } from '@/core/helpers/lang';

export default defineComponent({
    name  : 'Email',
    data(){
        return {
            email : '',
            reg: /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(.[a-zA-Z0-9]{2,3})+$/,
            valInput : false
        }
    },
    methods : {
        emitAdd(event){
            if(event=='adres'){
                if(this.reg.test(this.email)){
                    this.$emit('isActive', event);
                    this.$emit('email', this.email);
                }else {
                    this.valInput = true;
                }
            }else {
                 this.$emit('isActive', event);
            }
        }
    },
    setup(){
        return {
            l
        }
    }

});
